import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../static/css/speisekarte.css"
import "bootstrap/dist/css/bootstrap.min.css";
import { IoChevronBack } from "react-icons/io5";
import { IconContext } from "react-icons";
import Button from "react-bootstrap/Button";
import { jsPDF } from "jspdf";

import Tageskarte from "../components/SpeisekarteComponents/Tageskarte.js"
import Dauerkarte from "../components/SpeisekarteComponents/Dauerkarte.js";

import cover from "../static/img/SpeiseKarte/cover.jpg";
import s0 from "../static/img/SpeiseKarte/s0.jpg";
import p1 from "../static/img/SpeiseKarte/herbst_2024/p1.png";
import p2 from "../static/img/SpeiseKarte/herbst_2024/p2.png";
// import p3 from "../static/img/SpeiseKarte/wiesen_2024/p3.jpg";
// import p4 from "../static/img/SpeiseKarte/wiesen_2024/p4.jpg";
// import p5 from "../static/img/SpeiseKarte/06_04_2024/p5.png";
// import p6 from "../static/img/SpeiseKarte/06_04_2024/p6.png";
// import p7 from "../static/img/SpeiseKarte/06_04_2024/p7.png";
// import p8 from "../static/img/SpeiseKarte/06_04_2024/p8.png";
// import p9 from "../static/img/SpeiseKarte/06_04_2024/p9.png";
// import p10 from "../static/img/SpeiseKarte/06_04_2024/p10.png";
// import p11 from "../static/img/SpeiseKarte/06_04_2024/p11.png";
// import p12 from "../static/img/SpeiseKarte/06_04_2024/p12.png";
// import p13 from "../static/img/SpeiseKarte/06_04_2024/p13.png";
// import p14 from "../static/img/SpeiseKarte/06_04_2024/p14.png";
// import p15 from "../static/img/SpeiseKarte/06_04_2024/p15.png";
// import p16 from "../static/img/SpeiseKarte/06_04_2024/p16.png";




class Speisekarte extends Component {
  constructor(props) {
    super(props);

    this.handleSpeiseClick = this.handleSpeiseClick.bind(this);
    this.handleTagesClick = this.handleTagesClick.bind(this);
    this.state = { isLoggedIn: true };
  }

  pdfGenerate = () => {

    var doc = new jsPDF('p', 'px', 'a4', 'false');
    doc.addImage(p1, 'png', 40, 15, 380, 500)
    doc.addPage()
    doc.addImage(p2, 'png', 40, 15, 380, 500)
    doc.addPage()
    // doc.addImage(p5, 'png', 40, 15, 380, 500)
    // doc.addPage()
    // doc.addImage(p6, 'png', 40, 15, 380, 500)
    // doc.addPage()
    // doc.addImage(p7, 'png', 40, 15, 380, 500)
    // doc.addPage();
    // doc.addImage(p8, "png", 40, 15, 380, 500);
    // doc.addPage();
    // doc.addImage(p9, "png", 40, 15, 380, 500);
    // doc.addPage();
    // doc.addImage(p10, "png", 40, 15, 380, 500);
    // doc.addPage();
    // doc.addImage(p11, "png", 40, 15, 380, 500);
    // doc.addPage();
    // doc.addImage(p12, "png", 40, 15, 380, 500);
    // doc.addPage();
    // doc.addImage(p13, "png", 40, 15, 380, 500);
    // doc.addPage();
    // doc.addImage(p14, "png", 40, 15, 380, 500);
    // doc.addPage();
    // doc.addImage(p15, "png", 40, 15, 380, 500);
    // doc.addPage();
    // doc.addImage(p16, "png", 40, 15, 380, 500);

    doc.save('Speisekarte.pdf')

  }

  componentDidMount() {
    window.scrollTo(-100, 0);

  }

  handleSpeiseClick() {
    this.setState({ isLoggedIn: true });
  }

  handleTagesClick() {
    this.setState({ isLoggedIn: false });
  }
  render() {
    const isLoggedIn = this.state.isLoggedIn;
    var isFirefox = typeof InstallTrigger !== "undefined";

    let mnue;
    let karte;

    if (isFirefox) {
      mnue = (
        <div className="firefoxMenü">
          <Tageskarte />
          <Dauerkarte />
        </div>
      );
    } else {
      mnue = (
        <div className="SpeisekartenBoddy" id="bigspeise">
          <div className="SpeisekartenContainer">
            <input type="checkbox" id="c1" />
            <input type="checkbox" id="c2" />
            <input type="checkbox" id="c3" />
            {/* <input type="checkbox" id="c4" />
            <input type="checkbox" id="c5" />
            <input type="checkbox" id="c6" />
            <input type="checkbox" id="c7" />
            <input type="checkbox" id="c8" />
            <input type="checkbox" id="c9" /> */}
            <Tageskarte />
            {/*  */}

            <div className="page-container">
              {/* Cover-0 c1 */}
              <div className="page" id="p1">
                <div className="back">
                  <img src={s0} alt="img" />
                  <label className="back-btn" for="c1">
                    Back
                  </label>
                </div>
                <div className="front">
                  <img src={cover} alt="img" />
                  <label className="next-btn" for="c1">
                    Next
                  </label>
                </div>
              </div>
              {/* PAGE 1-2 c2 */}
              <div className="page" id="p2">
                <div className="back">
                  <img src={p2} alt="img" />
                  <label className="back-btn" for="c2">
                    Back
                  </label>
                </div>
                <div className="front">
                  <img src={p1} alt="img" />
                  <label className="next-btn" for="c2">
                    Next
                  </label>
                </div>
              </div>
              {/* PAGE 3-4 c3 */}
              {/* <div className="page" id="p3">
                <div className="back">
                  <img src={p4} alt="img" />
                  <label className="back-btn" for="c3">
                    Back
                  </label>
                </div>
                <div className="front">
                  <img src={p3} alt="img" />
                  <label className="next-btn" for="c3">
                    Next
                  </label>
                </div>
              </div> */}
              {/* PAGE 5-6 c4 */}
              {/* <div className="page" id="p4">
                <div className="back">
                  <img src={p6} alt="img" />
                  <label className="back-btn" for="c4">
                    Back
                  </label>
                </div>
                <div className="front">
                  <img src={p5} alt="img" />
                  <label className="next-btn" for="c4">
                    Next
                  </label>
                </div>
              </div> */}
              {/* PAGE 7-8 c5*/}
              {/* <div className="page" id="p5">
                <div className="back">
                  <img src={p8} alt="img" />
                  <label className="back-btn" for="c5">
                    Back
                  </label>
                </div>
                <div className="front">
                  <img src={p7} alt="img" />
                  <label className="next-btn" for="c5">
                    Next
                  </label>
                </div>
              </div> */}
              {/* PAGE 9-10 c6 */}
              {/* <div className="page" id="p6">
                <div className="back">
                  <img src={p10} alt="img" />
                  <label className="back-btn" for="c6">
                    Back
                  </label>
                </div>
                <div className="front">
                  <img src={p9} alt="img" />
                  <label className="next-btn" for="c6">
                    Next
                  </label>
                </div>
              </div> */}
              {/* PAGE 11-12 c7 */}
              {/* <div className="page" id="p7">
                <div className="back">
                  <img src={p12} alt="img" />
                  <label className="back-btn" for="c7">
                    Back
                  </label>
                </div>
                <div className="front">
                  <img src={p11} alt="img" />
                  <label className="next-btn" for="c7">
                    Next
                  </label>
                </div>
              </div> */}
              {/* PAGE 13-14 c8 */}
              {/* <div className="page" id="p8">
                <div className="back">
                  <img src={p14} alt="img" />
                  <label className="back-btn" for="c8">
                    Back
                  </label>
                </div>
                <div className="front">
                  <img src={p13} alt="img" />
                  <label className="next-btn" for="c8">
                    Next
                  </label>
                </div>
              </div> */}
              {/* PAGE 15-16 c9 */}
              {/* <div className="page" id="p9">
                <div className="back">
                  <img src={p16} alt="img" />
                  <label className="back-btn" for="c9">
                    Back
                  </label>
                </div>
                <div className="front">
                  <img src={p15} alt="img" />
                  <label className="next-btn" for="c9">
                    Next
                  </label>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      );
    }


    if (isLoggedIn) {
      karte = (
        <Dauerkarte />

      );
    } else {
      karte = (
        <div className="tageswrapper">
          <Tageskarte />

        </div>
      );
    }
    return (
      <div className="Speisekarte">
        <div className="SpeisekarteInnerContainer">
          <div className="HandyNavSpeise">
            <div className="GallerieTop" id="rellative">
              <div className="BackLink">
                <IconContext.Provider
                  value={{
                    color: "#000",
                    className: "global-class-name",
                    size: "30px",
                  }}
                >
                  <Link to="/">
                    <div className="tooltipCont">
                      <IoChevronBack />
                    </div>
                  </Link>
                </IconContext.Provider>
              </div>
              <div className="PageHeadding">
                <p>Speisekarte</p>
              </div>
              <div id="tel">
                <p href="tel:08995953634 " style={{ padding: '10px' }}>Tel: 08995953634 </p>
              </div>
            </div>
            <div className="KartenAussucher">
              <div
                id="Selected"
                onClick={this.handleSpeiseClick}
                className={this.state.isLoggedIn ? "StrichYES" : "StrichNO"}
              >
                Menü
              </div>
              <div
                id="notSelected"
                onClick={this.handleTagesClick}
                className={this.state.isLoggedIn ? "StrichNO" : "StrichYES"}
              >
                Tageskarte
              </div>
            </div>
          </div>

          <div className="compSpeisen">{mnue}</div>

          <div id="smallSpeise">{karte}</div>
          {/* id="KartenCintainr" muss da  -V-    vieeicht rein  */}
          {/* Big speise Fehlt dann Hier plazieren */}
        </div>
        <div className="Download">
          <p className="DownloadP">Speisekarte als PDF: </p>
          <Button
            variant="outline-secondary"
            className="downloadbutton"
            onClick={this.pdfGenerate}
          >
            PDF Herunterladen
          </Button>
        </div>
      </div>
    );
  }
}

export default Speisekarte;
