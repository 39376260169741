import "../../static/css/HomeStyle/Akt-Oef.css";

import React, { Component } from "react";

class Oeffnungszeiten extends Component {
  render() {
    return (
      <div className="CardContainer" id="spacer">
        <div className="Card">
          <p className="Headding">Öffnungszeiten</p>
          <div className="Oeffnungszeiten">
            <div id="middleoff" />
            <p id="discription">Montag Ruhetag</p>
            <p id="discription">aber im Dezember an allen Montagen geöffnet</p>
            <br />
            <p id="discription">Sonntag bis Donnerstag</p>
            <p id="">11:00 - 23:00 Uhr</p>
            <p id="discription">Freitag und Samstag</p>
            <p id="">11:00 - 24:00 Uhr</p>
            <br />
            <p id="underline">warme Küche</p>
            <p id="">bis 22:00 Uhr </p>
            <p id="underline">zum Abholen</p>
            <p id="time">bis 22:00 Uhr </p>
          </div>
        </div>
      </div>
      // <div className="oeffnungszeitenContainer">
      //   <img src={TischeQuer} id="one"></img>
      //   <img src={BierBreze} id="two"></img>
      //   <img src={Bierchen} id="thre"></img>
      //   <div className="ÖffnungsZeiten">
      //     {/* <img src={Zapfhan}></img> */}
      //     <div className="Background">
      //       <div className="Headding">
      //         {/* <h1>Willkomen</h1>
      //       <p>
      //         Wir füherne traditionel Bayrische gerichte und haben die ganze
      //         woche Geöffnet. Kommen sie vorbei, Geniesen sie ein Bier!
      //       </p> */}
      //       </div>
      //       <div className="information">
      //         <h2>Öffnungs Zeiten</h2>
      //         <p>Mo-So</p>
      //         <p>11:00-01:00 Uhr</p>
      //         <h3>warme Küche</h3>
      //         <p>11:30-22:00 Uhr</p>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default Oeffnungszeiten;
